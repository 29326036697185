<template>
    <b-container>
		<h1>Ülevaade</h1>

		<b-row class="mb-5">
			<b-col>
				<b-form inline style="flex-direction: row-reverse;">
					<b-form-datepicker
						id="end"
						v-model="end"
						:start-weekday="1"
						:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
						locale="et"
						label-current-month="Praegune kuu"
						label-next-month="Järgmine kuu"
						label-prev-month="Eelmine kuu"
						label-next-year="Järgmine aasta"
						label-prev-year="Eelmine aasta"
						style="width: 180px;"
						class="float-right ml-1"
						@input="loadData"
						right
					></b-form-datepicker>&nbsp;-&nbsp;
					<b-form-datepicker
						id="start"
						v-model="start"
						:start-weekday="1"
						:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
						locale="et"
						label-current-month="Praegune kuu"
						label-next-month="Järgmine kuu"
						label-prev-month="Eelmine kuu"
						label-next-year="Järgmine aasta"
						label-prev-year="Eelmine aasta"
						style="width: 180px;"
						class="float-right mr-1 ml-1"
						@input="loadData"
						right
					></b-form-datepicker>
					<span>Periood: </span>
				</b-form>
			</b-col>
		</b-row>

		<div v-for="(catData, catName) in chartsData" :key="catName" class="mb-4">
			<hr>
			<h4>{{ catName }}</h4>
			<v-chart class="chart"
					 style="height: 600px; width: 100%;"
					 :loading="loader"
					 :autoresize="true"
					 :option="catData.balanceChartData"
			/>
			<v-chart class="chart"
					 style="height: 800px; width: 100%;"
					 :loading="loader"
					 :autoresize="true"
					 :option="catData.treemapChartData"
			/>
		</div>

		<template v-if="loader">
			<div class="text-center text-primary my-2">
				<b-spinner variant="primary" class="align-middle"></b-spinner>
			</div>
		</template>

		<b-alert v-if="!loader && chartsData.length == 0" variant="info" show>
			Selles perioodivahemikus andmed puuduvad
		</b-alert>

    </b-container>
</template>

<script>

	import _ from 'lodash';
	import moment from "moment";

	import { use } from "echarts/core";
	import { CanvasRenderer } from "echarts/renderers";
	import { PieChart, BarChart, TreemapChart, LineChart } from "echarts/charts";
	import {
		TitleComponent,
		TooltipComponent,
		LegendComponent,
		GridComponent,
	} from "echarts/components";
	import VChart, { THEME_KEY } from "vue-echarts";

	use([
		CanvasRenderer,
		PieChart,
		BarChart,
		LineChart,
		TreemapChart,
		GridComponent,
		TitleComponent,
		TooltipComponent,
		LegendComponent
	]);

	export default {
		components: {
			VChart,
		},
		provide: {
			[THEME_KEY]: "light"
		},
		data () {
			return {
				loader: false,
				start: moment().subtract(1, 'year').format('YYYY-MM-DD'),
				end: moment().format('YYYY-MM-DD'),
				chartsData: false,
				option: {
					xAxis: {
						data: ['A', 'B', 'C', 'D', 'E']
					},
					yAxis: {},
					series: [
						{
							data: [10, 22, 28, 43, 49],
							type: 'bar',
							stack: 'x'
						},
						{
							data: [5, 4, 3, 5, 10],
							type: 'bar',
							stack: 'x'
						}
					]
				}
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			loadData() {
				this.loader = true;
				axios.get('/charts/chartkit_1/'+this.start+'/'+this.end).then(response => {
					this.chartsData = response.data;
					this.loader = false;
				});
			}
		}
	}
</script>
